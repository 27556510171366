.dis-acc-title-bar .modal-title {
    margin: 0;
    text-align: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: var(--color-dark-grey) !important;
  }
  .dis-acc-body-text {
    font-size: 16px;
    line-height: 1.13;
    color: var(--color-dark-grey) !important;
  }
  .dis-acc-title-bar span {
    /* position: absolute; */
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 !important;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
    color: var(--color-black);
  }
  .pp-dis-acc-btn-modal {
    width: 48%;
    margin: 0 !important;
    padding: 0;
    border-radius: 0;
    background: transparent !important;
    border-color: #fff !important;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
  }
  .dis-acc-bottom-bar {
    position: relative;
    border-top: 2px solid #dbdbdb !important;
  }
  .pp-dis-acc-btn-modal:hover {
    border-color: #fff;
  }
  .pp-dis-acc-btn-light-grey {
    color: var(--color-dark-grey) !important;
  }
  .pp-dis-acc-btn-dark-grey {
    color: var(--color-black) !important;
  }
  .yt-form-spacer {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto !important;
    display: block;
    bottom: 10px;
    width: 2px;
    top: 10px;
    background: #dbdbdb;
  }
  
  /*
    * Screen for under 1280 Resolution 
    */
  @media (max-width: 1279.92px) {
    .dis-acc-title-bar span {
      font-size: 18px;
      line-height: 1.11;
    }
    .dis-acc-body-text {
      line-height: 1.2;
      font-size: 15px;
      padding-top: 11px !important;
    }
    .pp-dis-acc-btn-modal {
      font-size: 15px;
      line-height: 1.2;
    }
  }
  /*
    * Screen for under 768 Resolution 
    */
  @media (max-width: 767.92px) {
    .dis-acc-title-bar span {
      font-size: 18px;
      line-height: 1.11;
    }
    .dis-acc-body-text {
      line-height: 1.13;
      font-size: 16px;
      padding-top: 0 !important;
    }
    .pp-dis-acc-btn-modal {
      font-size: 15px;
      line-height: 1.2;
    }
  }
  